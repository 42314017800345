div.hr {
    height: 25px;
    // justify-content: center;
    background: #fff url(../images/sep2.jpg) no-repeat scroll center;
    margin-top: 25px;
    padding-top: 10px;
    margin-bottom: 25px;
    padding-bottom: 10px;
    hr {
        display: none;
    }
}
img.thumbnail {
    // width: 190px;
    padding: .25rem;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    max-width: 150px;
    height: auto;
}

.mycontent-right {
    border-left: 1px solid #333;
    margin-left: 25px;
    padding-left: 25px;
}
/* CSS for the "myst" overlay */
.myst-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  /* CSS for the hover effect */
  .hovermyst:hover .myst-overlay {
    opacity: 0; /* Hide the overlay when the cursor is over the image */
  }
  
  .quiz-option, .quiz-option-correct{
    cursor: pointer;
  }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  bottom: auto;
  top: 5px;
  margin-bottom: 10px;
}

.list-group-flush>.list-group-item{
  background: beige;
  margin-top: 5px;
}

@media (min-width: 780px) {
  .fixed-left {
    position: fixed;
    left: 50px;
  }
}